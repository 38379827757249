import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box, Button, CircularProgress, IconButton, Modal, Typography,
} from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { initializeStripeIntent } from '../../utils/backend';
import CheckoutForm from './CheckoutForm';

const stripekey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(
  stripekey,
);

class DonateModal extends React.Component {
  constructor(props) {
    super(props);

    this.prepareDonation = this.prepareDonation.bind(this);

    this.state = {
      clientSecret: null,
      selectedAmount: null,
      isLoading: false,
    };
  }

  async prepareDonation(amount) {
    this.setState({ isLoading: true });
    const data = await initializeStripeIntent(amount);
    this.setState({ clientSecret: data.clientSecret, selectedAmount: amount, isLoading: false });
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { clientSecret, selectedAmount, isLoading } = this.state;

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    };

    const appearance = {
      theme: 'stripe',
    };

    const options = {
      clientSecret,
      appearance,
    };

    return (
      <Box data-testid="donateModalWrapper">
        <Modal
          data-testid="donateModal"
          open={isOpen}
          onClose={onClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{
            ...style, width: 400, paddingTop: '8px', paddingRight: '24px', paddingLeft: '24px',
          }}
          >
            <Box display="flex">
              <Box display="flex" marginLeft="auto">
                <IconButton onClick={onClose} data-testid="closeButton">
                  x
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" textAlign="center" flexDirection="column" marginBottom={2}>
              <Typography variant="h3" id="parent-modal-title">Donate</Typography>
              <Typography data-testid="donateDescription">
                Help me pay my server costs.
              </Typography>
            </Box>
            <Box width="100%" display="flex" textAlign="center" alignItems="center" marginBottom={2} alignSelf="center">
              {isLoading && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                data-testid="stripeIsLoadingWrapper"
                marginLeft="auto"
                marginRight="auto"
              >
                <Box>
                  <CircularProgress />
                </Box>
              </Box>
              )}
              {!selectedAmount && !isLoading && (
              <Box marginLeft="auto">
                <Button
                  variant="contained"
                  data-testid="donateFiveButton"
                  onClick={() => this.prepareDonation(5)}
                  color={selectedAmount === 5 ? 'primary' : 'inherit'}
                >
                  $5
                </Button>
              </Box>
              )}
              {!selectedAmount && !isLoading && (
              <Box marginLeft={1} marginRight={1}>
                <Button
                  variant="contained"
                  data-testid="donateTenButton"
                  onClick={() => this.prepareDonation(10)}
                  color={selectedAmount === 10 ? 'primary' : 'inherit'}
                >
                  $10
                </Button>
              </Box>
              )}
              {!selectedAmount && !isLoading && (
              <Box marginRight="auto">
                <Button
                  variant="contained"
                  data-testid="donateTwentyButton"
                  onClick={() => this.prepareDonation(20)}
                  color={selectedAmount === 20 ? 'primary' : 'inherit'}
                >
                  $20
                </Button>
              </Box>
              )}
              {selectedAmount && (
              <Box textAlign="center" marginLeft="auto" marginRight="auto">
                <Typography variant="h5">{`$${selectedAmount}`}</Typography>
              </Box>
              )}
            </Box>
            {clientSecret && (
              <Box>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>
              </Box>
            )}
          </Box>
        </Modal>
      </Box>
    );
  }
}

DonateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DonateModal;
