import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import MonologueStepOne from '../MonologueStepOne/MonologueStepOne';
import MonologueStepTwo from '../MonologueStepTwo/MonologueStepTwo';
import MonologueStepThree from '../MonologueStepThree/MonologueStepThree';
import MonologueStepFour from '../MonologueStepFour/MonologueStepFour';
import Table from '../../TheatreTable/TheatreTable';
import MonologueStepFive from '../MonologueStepFive/MonologueStepFive';

class MonologueParent extends Component {
  constructor(props) {
    super(props);

    this.setStep = this.setStep.bind(this);
    this.setText = this.setText.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.handleAddNewMonologue = this.handleAddNewMonologue.bind(this);

    this.state = {
      currentStep: 1,
    };
  }

  handleAddNewMonologue() {
    const { onAddMonologue } = this.props;
    this.setStep(1);
    onAddMonologue();
  }

  setStep(step) {
    this.setState({ currentStep: step });
  }

  setText(newText) {
    const { monologues, currentMonologue, onSetMonologue } = this.props;
    const monologueToUpdate = { ...monologues[currentMonologue] };
    monologueToUpdate.text = newText;
    onSetMonologue(monologueToUpdate, currentMonologue);
  }

  setTitle(newTitle) {
    const { monologues, currentMonologue, onSetMonologue } = this.props;
    const monologueToUpdate = { ...monologues[currentMonologue] };
    monologueToUpdate.title = newTitle;
    onSetMonologue(monologueToUpdate, currentMonologue);
  }

  render() {
    const { currentStep } = this.state;
    const {
      monologues, currentMonologue, onSetCurrentMonologue, onDeleteMonologue,
    } = this.props;
    const { text, title } = monologues[currentMonologue];
    const brokenUpAtLineBreaks = text.split('\n');
    return (
      <Box data-testid="monologueParent">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={3}
        >
          <Button
            data-testid="addNewMonologueButton"
            variant="contained"
            onClick={() => this.handleAddNewMonologue()}
          >
            New Monologue
          </Button>
        </Box>
        <Box data-testid="monologueModeWrapper">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={2}
          >
            <Box marginRight={1}>
              {currentStep > 1
                && (
                <Button
                  data-testid="previousStepTopButton"
                  variant="contained"
                  onClick={() => this.setStep(currentStep - 1)}
                >
                  Previous Step
                </Button>
                )}
            </Box>
            <Box marginLeft={1}>
              {currentStep < 5
                && (
                <Button
                  data-testid="nextStepTopButton"
                  variant="contained"
                  onClick={() => this.setStep(currentStep + 1)}
                >
                  Next Step
                </Button>
                )}
            </Box>
          </Box>
          {currentStep === 1 && (
          <MonologueStepOne
            text={text}
            onSetText={this.setText}
            onSetTitle={this.setTitle}
            title={title}
          />
          )}
          {currentStep === 2 && (
          <MonologueStepTwo
            text={brokenUpAtLineBreaks}
            title={title}
          />
          )}
          {currentStep === 3 && (
          <MonologueStepThree
            text={brokenUpAtLineBreaks}
            title={title}
          />
          )}
          {currentStep === 4 && (
          <MonologueStepFour
            text={brokenUpAtLineBreaks}
            title={title}
          />
          )}
          {currentStep === 5 && (
            <MonologueStepFive
              title={title}
            />
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <Box marginRight={1}>
            {currentStep > 1
              && (
              <Button
                data-testid="previousStepBottomButton"
                variant="contained"
                onClick={() => this.setStep(currentStep - 1)}
              >
                Previous Step
              </Button>
              )}
          </Box>
          <Box marginLeft={1}>
            {currentStep < 5
              && (
              <Button
                data-testid="nextStepBottomButton"
                variant="contained"
                onClick={() => this.setStep(currentStep + 1)}
              >
                Next Step
              </Button>
              )}
          </Box>
        </Box>
        <Table
          items={monologues}
          currentItem={currentMonologue}
          onSetCurrentItem={onSetCurrentMonologue}
          onDeleteItem={onDeleteMonologue}
        />
      </Box>
    );
  }
}

MonologueParent.propTypes = {
  monologues: PropTypes.arrayOf(
    PropTypes.shape(
      { title: PropTypes.string.isRequired, text: PropTypes.string.isRequired },
    ).isRequired,
  ).isRequired,
  onSetMonologue: PropTypes.func.isRequired,
  onAddMonologue: PropTypes.func.isRequired,
  onDeleteMonologue: PropTypes.func.isRequired,
  onSetCurrentMonologue: PropTypes.func.isRequired,
  currentMonologue: PropTypes.number.isRequired,
};

export default MonologueParent;
