import React from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';

function MonologueStepFive({ title }) {
  return (
    <Box data-testid="monologueStepFive">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={3}
      >
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="80%"
          sx={{
            backgroundColor: 'rgb(231, 231, 231);',
            borderRadius: '16px',
          }}
          padding={3}
          textAlign="center"
        >
          <Typography variant="h5">Great job! Now repeat it on your own!</Typography>
        </Box>
      </Box>
    </Box>
  );
}

MonologueStepFive.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MonologueStepFive;
