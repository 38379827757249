/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as PropTypes from 'prop-types';
import { useSpeechSynthesis } from 'react-speech-kit';
import { Box, Button, Typography } from '@material-ui/core';
import { cloneDeep, removeLettersAfterFirstAndPreservePunctuation } from '../../../utils/utils';

function SceneStepThree(props) {
  const { speak } = useSpeechSynthesis();
  const {
    text, title,
  } = props;
  const clonedText = cloneDeep(text);
  const modifiedText = clonedText.map((line) => {
    const modifiedLine = line;
    if (line.isMine) {
      modifiedLine.text = removeLettersAfterFirstAndPreservePunctuation(line.text);
    }
    return modifiedLine;
  });
  return (
    <Box data-testid="sceneStepThreeWrapper">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={3}
      >
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="80%"
          sx={{
            backgroundColor: 'rgb(231, 231, 231);',
            borderRadius: '16px',
          }}
          padding={3}
        >
          {modifiedText.map((textUnit, i) => (
            <Box key={`sceneLine-${i}`} display="flex" width="100%" alignItems="center">
              <Box
                width={70}
                height={30}
                display="flex"
                alignItems="center"
              >
                {!textUnit.isMine && textUnit.text && (
                  <Box alignItems="center" paddingTop={0.5}>
                    <Button
                      data-testid={`readButton${i}`}
                      variant="outlined"
                      style={{
                        maxWidth: '50px', maxHeight: '20px', minWidth: '50px', minHeight: '20px', width: '50px',
                      }}
                      onClick={() => speak({ text: textUnit.text })}
                    >
                      Speak
                    </Button>
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                marginLeft={1}
                paddingTop={1}
                width="100%"
              >
                <Typography key={`sceneLine-${i}`}>{textUnit.text}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

SceneStepThree.propTypes = {
  text: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      isMine: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default SceneStepThree;
