import React from 'react';
import {
  Box, TextareaAutosize,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';

function MonologueStepOne({
  text, onSetText, onSetTitle, title,
}) {
  return (
    <Box data-testid="monologueStepOne">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextareaAutosize
          data-testid="titleTextArea"
          placeholder="Enter Title"
          style={{
            textAlign: 'center',
            width: '60%',
            fontSize: 30,
            resize: 'none',
            borderRadius: '6px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          }}
          onChange={(e) => onSetTitle(e.target.value)}
          value={title}
        />
      </Box>
      <Box
        marginTop={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextareaAutosize
          data-testid="monologueTextarea"
          placeholder="Write your monologue here. Use line breaks between lines!"
          minRows={10}
          maxRows={25}
          style={{
            width: '90%',
            fontSize: '16px',
            resize: 'none',
            borderRadius: '6px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          }}
          value={text}
          onChange={(e) => onSetText(e.target.value)}
        />
      </Box>
    </Box>
  );
}

MonologueStepOne.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSetText: PropTypes.func.isRequired,
  onSetTitle: PropTypes.func.isRequired,
};

export default MonologueStepOne;
