/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, TextareaAutosize } from '@material-ui/core';
import SceneLineInput from '../SceneLineInput/SceneLineInput';

function SceneStepOne(props) {
  const {
    onSetText, onSetMine, text, onPushLineIntoTextArray, onSetTitle, title,
  } = props;
  return (
    <div data-testid="sceneStepOneWrapper">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextareaAutosize
          data-testid="titleTextArea"
          placeholder="Enter Title"
          style={{
            textAlign: 'center',
            width: '60%',
            fontSize: 30,
            borderRadius: '6px',
            resize: 'none',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          }}
          onChange={(e) => onSetTitle(e.target.value)}
          value={title}
        />
      </Box>
      <Box
        marginTop={2}
        display="flex"
        flexDirection="column"
      >
        {text.map((line, i) => (
          <Box
            marginLeft={5}
            key={`sceneStepOne-${i}`}
          >
            <SceneLineInput
              key={`sceneLineInput-${i}`}
              text={line.text}
              mine={line.isMine}
              onSetText={onSetText}
              onSetMine={onSetMine}
              index={i}
              isLast={i === text.length - 1}
              onHandleIsLastFocus={onPushLineIntoTextArray}
            />
          </Box>
        ))}
      </Box>
    </div>
  );
}

SceneStepOne.propTypes = {
  text: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      isMine: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  onSetTitle: PropTypes.func.isRequired,
  onSetText: PropTypes.func.isRequired,
  onSetMine: PropTypes.func.isRequired,
  onPushLineIntoTextArray: PropTypes.func.isRequired,
};

export default SceneStepOne;
