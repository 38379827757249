import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

if (window.location.protocol === 'http:') {
  window.location.href = window.location.href.replace(
    'http:', 'https:',
  );
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
