import {
  Box, IconButton, Modal, Typography,
} from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';

function ThankYouModal(props) {
  const { isOpen, onClose } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Box data-testid="thankYouModalWrapper">
      <Modal
        data-testid="donateModal"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{
          ...style, width: 400, paddingTop: '8px', paddingRight: '24px', paddingLeft: '24px',
        }}
        >
          <Box display="flex">
            <Box display="flex" marginLeft="auto">
              <IconButton onClick={onClose} data-testid="closeButton">
                x
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" textAlign="center" flexDirection="column" marginBottom={2}>
            <Typography variant="h3" id="parent-modal-title">Thank You!</Typography>
            <Typography data-testid="thankYouDescription">
              Thank you for your donation. You&#39;re really helping me out.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

ThankYouModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ThankYouModal;
