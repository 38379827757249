import React from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { removeLettersAfterFirstAndPreservePunctuation } from '../../../utils/utils';

function MonologueStepThree({ text, title }) {
  const textWithRemovedCharacters = text.map((line) => removeLettersAfterFirstAndPreservePunctuation(line));
  return (
    <Box data-testid="monologueStepThree">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={3}
      >
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="80%"
          sx={{
            backgroundColor: 'rgb(231, 231, 231);',
            borderRadius: '16px',
          }}
          padding={3}
        >
          {/* This will be a static list at the time of render, the index is the easiest way to make unique key */}
          {/* eslint-disable-next-line react/no-array-index-key */}
          {textWithRemovedCharacters.map((line, i) => <Typography key={`${line}-${i}`}>{line}</Typography>)}
        </Box>
      </Box>
    </Box>
  );
}

MonologueStepThree.propTypes = {
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default MonologueStepThree;
