/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as PropTypes from 'prop-types';
import { useSpeechSynthesis } from 'react-speech-kit';
import { Box, Button, Typography } from '@material-ui/core';
import {
  cloneDeep, groupLinesAndRemoveMine,
} from '../../../utils/utils';

function SceneStepSix(props) {
  const {
    text, title,
  } = props;
  const { speak } = useSpeechSynthesis();
  const clonedText = cloneDeep(text);
  const modifiedText = groupLinesAndRemoveMine(clonedText);
  return (
    <Box data-testid="sceneStepSixWrapper">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={3}
      >
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="80%"
          sx={{
            backgroundColor: 'rgb(231, 231, 231);',
            borderRadius: '16px',
          }}
          textAlign="center"
          padding={3}
        >
          <Typography variant="h5">
            {"Great job! Your scene partners' lines have been grouped to be read "
              + 'until you have a line. See how you do on your own!'}
          </Typography>
          {modifiedText.map((line, i) => (
            <Box
              key={`sceneLine-${i}`}
              display="flex"
              width="100%"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
              >
                <Box alignItems="center" paddingTop={0.5}>
                  <Button
                    data-testid={`readButton${i}`}
                    variant="outlined"
                    onClick={() => speak({ text: line })}
                  >
                    Play Scene Partners&#39; Lines
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

SceneStepSix.propTypes = {
  text: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      isMine: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default SceneStepSix;
