import React, { Component } from 'react';
import {
  AppBar, Box, Button, TextField, Toolbar, Typography,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';

class Nav extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = { inputText: '' };
  }

  handleChange(e) {
    this.setState({ inputText: e.currentTarget.value });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { onLogin } = this.props;
    const { inputText } = this.state;
    await onLogin({ username: inputText });
  }

  render() {
    const { inputText } = this.state;
    const { username, onLogout, onOpenDonateModal } = this.props;
    return (
      <div data-testid="navWrapper">
        <AppBar position="static" style={{ background: '#b5b5b5' }}>
          <Toolbar variant="dense">
            <Box>
              <Button
                data-testid="openDonateModalButton"
                variant="contained"
                size="small"
                onClick={() => onOpenDonateModal()}
              >
                Donate
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ marginLeft: 'auto' }}
            >
              {!username && (
              <form id="loginForm" data-testid="loginForm" onSubmit={this.handleSubmit}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextField
                      inputProps={{ 'data-testid': 'usernameTextInput' }}
                      label="Unique Username"
                      variant="outlined"
                      id="usernameInput"
                      size="small"
                      onChange={this.handleChange}
                      value={inputText}
                      color="secondary"
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginLeft={1}
                  >
                    <Button data-testid="loginSubmitButton" type="submit" variant="contained" size="small">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </form>
              )}
            </Box>
            {username && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ marginLeft: 'auto' }}
            >
              <Typography>{`Logged in as ${username}`}</Typography>
              <Box marginLeft={1}>
                <Button
                  data-testid="logoutButton"
                  variant="contained"
                  size="small"
                  onClick={() => onLogout()}
                >
                  Log Out
                </Button>
              </Box>
            </Box>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Nav.propTypes = {
  onLogin: PropTypes.func.isRequired,
  username: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  onOpenDonateModal: PropTypes.func.isRequired,
};

Nav.defaultProps = {
  username: null,
};

export default Nav;
