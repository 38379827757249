/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Box, Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import SceneStepOne from '../SceneStepOne/SceneStepOne';
import SceneStepThree from '../SceneStepThree/SceneStepThree';
import SceneStepTwo from '../SceneStepTwo/SceneStepTwo';
import SceneStepFour from '../SceneStepFour/SceneStepFour';
import SceneStepFive from '../SceneStepFive/SceneStepFive';
import { cloneDeep } from '../../../utils/utils';
import Table from '../../TheatreTable/TheatreTable';
import SceneStepSix from '../SceneStepSix/SceneStepSix';

class SceneParent extends Component {
  constructor(props) {
    super(props);

    this.setStep = this.setStep.bind(this);
    this.setText = this.setText.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setMine = this.setMine.bind(this);

    this.state = {
      currentStep: 1,
    };
  }

  handleAddNewScene() {
    const { onAddNewScene } = this.props;
    this.setStep(1);
    onAddNewScene();
  }

  setTitle(newTitle) {
    const { scenes, currentScene, onSetScene } = this.props;
    const scenesClone = cloneDeep(scenes);
    scenesClone[currentScene].title = newTitle;
    onSetScene(scenesClone, currentScene);
  }

  setMine(index, checked) {
    const { scenes, currentScene, onSetScene } = this.props;
    const scenesClone = cloneDeep(scenes);
    scenesClone[currentScene].text[index].isMine = checked;
    onSetScene(scenesClone);
  }

  setText(index, newText) {
    const { scenes, currentScene, onSetScene } = this.props;
    const scenesClone = cloneDeep(scenes);
    scenesClone[currentScene].text[index].text = newText;
    onSetScene(scenesClone, currentScene);
  }

  setStep(step) {
    this.setState({ currentStep: step });
  }

  render() {
    const { currentStep } = this.state;
    const {
      scenes, currentScene, onPushLineIntoSceneTextArray, onSetCurrentScene, onDeleteScene,
    } = this.props;
    const { text, title } = scenes[currentScene];
    return (
      <div data-testid="sceneModeWrapper">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={3}
        >
          <Button
            data-testid="addNewSceneButton"
            variant="contained"
            onClick={() => this.handleAddNewScene()}
          >
            New Scene
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={2}
        >
          <Box marginRight={1}>
            {currentStep > 1 && (
            <Button
              data-testid="previousStepTopButton"
              variant="contained"
              onClick={() => this.setStep(currentStep - 1)}
            >
              Previous Step
            </Button>
            )}
          </Box>
          <Box marginLeft={1}>
            {currentStep < 6 && (
            <Button
              data-testid="nextStepTopButton"
              variant="contained"
              onClick={() => this.setStep(currentStep + 1)}
            >
              Next Step
            </Button>
            )}
          </Box>
        </Box>
        {currentStep === 1 && (
        <SceneStepOne
          text={text}
          title={title}
          onSetMine={this.setMine}
          onSetText={this.setText}
          onSetTitle={this.setTitle}
          onPushLineIntoTextArray={onPushLineIntoSceneTextArray}
        />
        )}
        {currentStep === 2 && <SceneStepTwo text={text} title={title} />}
        {currentStep === 3 && <SceneStepThree text={text} title={title} />}
        {currentStep === 4 && <SceneStepFour text={text} title={title} />}
        {currentStep === 5 && <SceneStepFive text={text} title={title} />}
        {currentStep === 6 && <SceneStepSix text={text} title={title} />}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <Box marginRight={1}>
            {currentStep > 1 && (
            <Button
              data-testid="previousStepBottomButton"
              variant="contained"
              onClick={() => this.setStep(currentStep - 1)}
            >
              Previous Step
            </Button>
            )}
          </Box>
          <Box marginLeft={1}>
            {currentStep < 6 && (
            <Button
              data-testid="nextStepBottomButton"
              variant="contained"
              onClick={() => this.setStep(currentStep + 1)}
            >
              Next Step
            </Button>
            )}
          </Box>
        </Box>
        <Table
          items={scenes}
          currentItem={currentScene}
          onSetCurrentItem={onSetCurrentScene}
          onDeleteItem={onDeleteScene}
        />
      </div>
    );
  }
}

SceneParent.propTypes = {
  scenes: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      isMine: PropTypes.bool.isRequired,
    }).isRequired).isRequired,
  })).isRequired,
  currentScene: PropTypes.number.isRequired,
  onSetScene: PropTypes.func.isRequired,
  onPushLineIntoSceneTextArray: PropTypes.func.isRequired,
  onSetCurrentScene: PropTypes.func.isRequired,
  onAddNewScene: PropTypes.func.isRequired,
  onDeleteScene: PropTypes.func.isRequired,
};

export default SceneParent;
