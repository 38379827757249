/* istanbul ignore file */
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const paymentUrl = process.env.REACT_APP_REITH_PAYMENT_SERVICE_URL;

export async function login({ username, browserFingerprint }) {
  const result = await fetch(`${backendUrl}/login`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, browserFingerprint }),
  });
  if (result.ok) {
    return result.json();
  }
  throw Error(`Could not log in user with username ${username}.`);
}

export async function save(data) {
  const result = await fetch(`${backendUrl}/save`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (result.ok) {
    return result.json();
  }
  throw Error('Save failed');
}

export async function initializeStripeIntent(amount) {
  const result = await fetch(`${paymentUrl}/create-donation`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ amount }),
  });
  if (result.ok) {
    return result.json();
  }
  throw Error('Failed to initialize payment');
}
