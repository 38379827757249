/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Box,
  Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';

function TheatreTable(props) {
  const {
    items, currentItem, onSetCurrentItem, onDeleteItem,
  } = props;
  return (
    <Box data-testid="theatreTableWrapper" marginTop={3} marginLeft={3} marginRight={3}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Current</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((scene, i) => (
              <TableRow key={`${scene.title}-${i}`}>
                <TableCell>
                  <Button
                    data-testid={`setItemButton${i}`}
                    onClick={() => onSetCurrentItem(i)}
                    color={i === currentItem ? 'primary' : 'inherit'}
                    variant="outlined"
                    style={{ width: '82px', height: '30px' }}
                  >
                    {i === currentItem ? 'Active' : 'Load'}
                  </Button>
                </TableCell>
                <TableCell data-testid={`title${i}`}>{scene.title}</TableCell>
                <TableCell>
                  {items.length > 1 && (
                  <Button
                    data-testid={`deleteItemButton${i}`}
                    variant="outlined"
                    onClick={() => onDeleteItem(i)}
                  >
                    Delete
                  </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

TheatreTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })).isRequired,
  currentItem: PropTypes.number.isRequired,
  onSetCurrentItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export default TheatreTable;
