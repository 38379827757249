import React, { Component } from 'react';
import {
  Box,
  Button, CircularProgress, Typography,
} from '@material-ui/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { MODES } from './constants';
import MonologueParent from '../Monologue/MonologueParent/MonologueParent';
import SceneParent from '../Scene/SceneParent/SceneParent';
import { cloneDeep } from '../../utils/utils';
import { login, save } from '../../utils/backend';
import Nav from '../Nav/Nav';
import DonateModal from '../DonateModal/DonateModal';
import ThankYouModal from '../ThankYouModal/ThankYouModal';

class Parent extends Component {
  constructor(props) {
    super(props);

    this.setMode = this.setMode.bind(this);
    this.save = this.save.bind(this);
    this.setMonologue = this.setMonologue.bind(this);
    this.setScene = this.setScene.bind(this);
    this.pushLineIntoSceneTextArray = this.pushLineIntoSceneTextArray.bind(this);
    this.login = this.login.bind(this);
    this.setCurrentScene = this.setCurrentScene.bind(this);
    this.addNewScene = this.addNewScene.bind(this);
    this.deleteScene = this.deleteScene.bind(this);
    this.addMonologue = this.addMonologue.bind(this);
    this.deleteMonologue = this.deleteMonologue.bind(this);
    this.setCurrentMonologue = this.setCurrentMonologue.bind(this);
    this.logout = this.logout.bind(this);
    this.openDonateModal = this.openDonateModal.bind(this);
    this.closeDonateModal = this.closeDonateModal.bind(this);

    const urlParams = new URLSearchParams(window.location.search);
    const thankYouParam = urlParams.get('thankyou');
    this.state = {
      mode: MODES.SCENE,
      browserFingerprint: null,
      username: localStorage.getItem('username') || null,
      monologues: [{ title: '', text: '' }],
      currentMonologue: 0,
      scenes: [{ title: '', text: [{ text: '', isMine: false }] }],
      currentScene: 0,
      isLoading: true,
      donateModalIsOpen: false,
      thankYouModalIsOpen: !!thankYouParam,
    };
    this.timeout = 0;
  }

  async componentDidMount() {
    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();
    this.setState({ browserFingerprint: visitorId });
    const username = localStorage.getItem('username');
    await this.login({ username, browserFingerprint: visitorId }, false);
  }

  setMode(mode) {
    this.setState({ mode });
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  setMonologue(monologue, index) {
    this.setState((currentState) => {
      const currentMonologues = [...currentState.monologues];
      currentMonologues[index] = monologue;
      return { ...currentState, monologues: currentMonologues };
    });
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  setScene(newScenes) {
    this.setState((state) => ({ ...state, scenes: newScenes }));
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  setCurrentScene(newSceneIndex) {
    this.setState({ currentScene: newSceneIndex });
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  setCurrentMonologue(newMonologueIndex) {
    this.setState({ currentMonologue: newMonologueIndex });
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  addMonologue() {
    const { monologues } = this.state;
    const monologuesClone = cloneDeep(monologues);
    monologuesClone.push({ title: '', text: '', updatedAt: new Date().toISOString() });
    this.setState({ monologues: monologuesClone, currentMonologue: monologuesClone.length - 1 });
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  deleteMonologue(index) {
    const { monologues, currentMonologue } = this.state;
    const monologuesClone = cloneDeep(monologues);
    monologuesClone.splice(index, 1);
    if (index === currentMonologue) {
      this.setState({ monologues: monologuesClone, currentMonologue: 0 });
    } else if (currentMonologue === monologuesClone.length) {
      this.setState({ monologues: monologuesClone, currentMonologue: monologuesClone.length - 1 });
    } else {
      this.setState({ monologues: monologuesClone });
    }
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  addNewScene() {
    const { scenes } = this.state;
    const scenesClone = cloneDeep(scenes);
    scenesClone.push({ title: '', text: [{ text: '', isMine: false }], updatedAt: new Date().toISOString() });
    this.setState({ scenes: scenesClone, currentScene: scenesClone.length - 1 });
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  deleteScene(index) {
    const { scenes, currentScene } = this.state;
    const scenesClone = cloneDeep(scenes);
    scenesClone.splice(index, 1);
    if (index === currentScene) {
      this.setState({ scenes: scenesClone, currentScene: 0 });
    } else if (currentScene === scenesClone.length) {
      this.setState({ scenes: scenesClone, currentScene: scenesClone.length - 1 });
    } else {
      this.setState({ scenes: scenesClone });
    }
    // noinspection JSIgnoredPromiseFromCall
    this.save();
  }

  pushLineIntoSceneTextArray() {
    this.setState((state) => {
      const { scenes, currentScene } = state;
      const scenesClone = cloneDeep(scenes);
      scenesClone[currentScene].text.push({ text: '', isMine: false });
      return { ...state, scenes: scenesClone };
    });
  }

  openDonateModal() {
    this.setState({ donateModalIsOpen: true });
  }

  closeDonateModal() {
    this.setState({ donateModalIsOpen: false });
  }

  async login({ username, browserFingerprint }) {
    const { browserFingerprint: browserFingerprintFromState } = this.state;
    let data;
    if (username) {
      data = await login(
        {
          username,
          browserFingerprint: browserFingerprint || browserFingerprintFromState,
        },
      );
    } else {
      data = await login({ browserFingerprint });
    }
    if (data && data.status === 'OK') {
      const {
        monologues, scenes, mode, currentMonologue, currentScene,
      } = data.data;
      this.setState({
        monologues,
        scenes,
        mode,
        currentMonologue,
        currentScene,
        username: username || data.username || null,
        isLoading: false,
      });
      if (username) {
        localStorage.setItem('username', username);
      }
    }
  }

  logout() {
    localStorage.removeItem('username');
    this.setState({
      username: null,
      browserFingerprint: null,
      monologues: [{ title: '', text: '' }],
      currentMonologue: 0,
      scenes: [{ title: '', text: [{ text: '', isMine: false }] }],
      currentScene: 0,
    });
  }

  async save() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(async () => {
      const {
        browserFingerprint, monologues, scenes, username, mode, currentScene, currentMonologue,
      } = this.state;
      const data = {
        browserFingerprint,
        username,
        monologues,
        scenes,
        mode,
        currentScene,
        currentMonologue,
      };
      await save(data);
    }, 2000);
  }

  render() {
    const {
      mode, monologues,
      scenes, currentMonologue,
      currentScene, username,
      isLoading, donateModalIsOpen,
      thankYouModalIsOpen,
    } = this.state;
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={10}
          data-testid="isLoadingWrapper"
        >
          <Box>
            <CircularProgress />
          </Box>
          <Box marginLeft={3}>
            <Typography variant="h5">Loading...</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box data-testid="parent">
        <Nav onLogin={this.login} username={username} onLogout={this.logout} onOpenDonateModal={this.openDonateModal} />
        <DonateModal isOpen={donateModalIsOpen} onClose={this.closeDonateModal} />
        <ThankYouModal isOpen={thankYouModalIsOpen} onClose={() => this.setState({ thankYouModalIsOpen: false })} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Box marginRight={1}>
            <Button
              variant="contained"
              data-testid="setModeToMonologueButton"
              onClick={() => this.setMode(MODES.MONOLOGUE)}
            >
              My Monologues
            </Button>
          </Box>
          <Box marginLeft={1}>
            <Button
              variant="contained"
              data-testid="setModeToSceneButton"
              onClick={() => this.setMode(MODES.SCENE)}
            >
              My Scenes
            </Button>
          </Box>
        </Box>
        {mode === MODES.MONOLOGUE && (
          <MonologueParent
            monologues={monologues}
            currentMonologue={currentMonologue}
            onSetMonologue={this.setMonologue}
            onAddMonologue={this.addMonologue}
            onDeleteMonologue={this.deleteMonologue}
            onSetCurrentMonologue={this.setCurrentMonologue}
          />
        )}
        {mode === MODES.SCENE && (
          <SceneParent
            scenes={scenes}
            currentScene={currentScene}
            onSetScene={this.setScene}
            onSetCurrentScene={this.setCurrentScene}
            onAddNewScene={this.addNewScene}
            onPushLineIntoSceneTextArray={this.pushLineIntoSceneTextArray}
            onDeleteScene={this.deleteScene}
          />
        )}
      </Box>
    );
  }
}

export default Parent;
