export function cloneDeep(object) {
  return JSON.parse(JSON.stringify(object));
}

// eslint-disable-next-line import/prefer-default-export
export function removeLettersAfterFirstAndPreservePunctuation(line) {
  const lineArray = line.split(' ');
  for (let i = 0; i < lineArray.length; i += 1) {
    const wordArray = lineArray[i].split('');
    for (let j = 1; j < wordArray.length; j += 1) {
      if (wordArray[j].match(/^[0-9a-zA-Z]+$/) && !['"', '“'].includes(wordArray[j - 1])) {
        wordArray[j] = '_';
      }
    }
    lineArray[i] = wordArray.join('');
  }
  return lineArray.join(' ');
}

export function removeWordsFromLinesExceptFirstTwo(text) {
  if (typeof text[0] === 'string') {
    return text.map((line) => line.split(' ').slice(0, 2).join(' '));
  }
  return text.map((line) => {
    const modifiedLine = line;
    if (line.isMine === true) {
      modifiedLine.text = line.text.split(' ').slice(0, 2).join(' ');
    }
    return modifiedLine;
  });
}

export function replaceLinesThatAreMineWithYourLine(text) {
  return text.map((line) => {
    const modifiedLine = line;
    if (line.isMine === true) {
      modifiedLine.text = '[YOUR LINE]';
    }
    return modifiedLine;
  });
}

export function groupLinesAndRemoveMine(text) {
  const finalArray = [];
  let currentString = '';
  for (let i = 0; i < text.length; i += 1) {
    if (text[i].isMine) {
      finalArray.push(currentString);
      currentString = '';
    } else {
      currentString = `${currentString} \n${text[i].text}`;
    }
    if (i === text.length - 1 && currentString) {
      finalArray.push(currentString);
    }
  }
  return finalArray.map((line) => (line.trim())).filter((n) => n);
}
