import React from 'react';
import {
  Box, Checkbox, TextareaAutosize, Tooltip,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';

function SceneLineInput({
  text, onSetText, index, mine, onSetMine, isLast, onHandleIsLastFocus,
}) {
  return (
    <Box data-testid="stepOne" key={`sceneLineInput-${index}`} display="flex" alignItems="center">
      <Tooltip
        title="Mine"
        placement="top"
        arrow
      >
        <Box display="flex">
          <Checkbox
            inputProps={{ 'data-testid': `mineCheckbox${index}` }}
            onChange={(event) => {
              onSetMine(index, event.target.checked);
            }}
            checked={mine}
            tabIndex={-1}
          />
        </Box>
      </Tooltip>
      <Box display="flex" width="100%" alignItems="center">
        <TextareaAutosize
          placeholder="Type a line from your scene here. Check the box to the left if it is your line."
          data-testid={`sceneTextareaLine${index}`}
          value={text}
          onChange={(e) => onSetText(index, e.target.value)}
          onFocus={() => {
            if (isLast) {
              onHandleIsLastFocus();
            }
          }}
          style={{
            width: '90%',
            fontSize: '16px',
            borderRadius: '6px',
            resize: 'none',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          }}
          minRows={1}
        />
      </Box>
    </Box>
  );
}

SceneLineInput.propTypes = {
  text: PropTypes.string.isRequired,
  onSetText: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  mine: PropTypes.bool.isRequired,
  onSetMine: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired,
  onHandleIsLastFocus: PropTypes.func.isRequired,
};

export default SceneLineInput;
